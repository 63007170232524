import { differenceInHours } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { CSSProperties } from 'styled-components';
import { Entities } from 'twitter-d';
import type { Tweet } from '../../../../packages/shared/types';
import { API_URL } from '../../constants';
import { createTweetComponent } from '../../helpers/react-tweet-parser';
import OutlinedButton from '../CoreUI/OutlinedButton';
import Tooltip from '../CoreUI/Tooltip';
import Loading from '../shared/Loading';
import './tweets.css';

const fetchTweets = async (cursor: string | null, limit: number) => {
  let url = `${API_URL}/tweets`;

  url += `?limit=${limit}`;

  if (cursor !== null) {
    url += `&cursor=${encodeURIComponent(cursor)}`;
  }

  const tweetsReq = await fetch(url);

  const tweets = await tweetsReq.json();
  return tweets.tweets ?? [];
};

interface TweetGridProps {}

const TweetGrid: React.FC<TweetGridProps> = () => {
  const [lastTweetCount, setLastTweetCount] = useState(0);
  const [tweetCount, setTweetCount] = useState(4);
  const [tweets, setTweets] = useState<Array<Tweet>>([]);

  function increaseTweetCount() {
    setLastTweetCount(tweetCount);
    setTweetCount((x) => x + 4);
  }

  function resetTweetCount() {
    setLastTweetCount(0);
    setTweetCount(4);
  }

  useEffect(() => {
    const getTweets = async (cursor: string | null, limit: number) => {
      const tweets = await fetchTweets(cursor, limit);
      setTweets((x) => [...x, ...tweets]);
    };

    if (tweets.length === 0) {
      setTimeout(() => getTweets(null, tweetCount), 0);
    } else if (tweets.length < tweetCount) {
      getTweets(
        tweets[tweets.length - 1].created_at,
        tweetCount - tweets.length,
      );
    }
  }, [tweetCount]);

  const arrayMap = [...Array(tweetCount).keys()];

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
        {arrayMap.map((idx) => (
          <TweetTile
            key={idx}
            tweet={tweets[idx]}
            delayIdx={idx - lastTweetCount}
            idx={idx}
            // zIndex={100 - idx}
          />
        ))}
      </div>
      <div className="my-4 mt-4 flex justify-center">
        <OutlinedButton onClick={increaseTweetCount}>Show More</OutlinedButton>
        {tweetCount > 4 ? (
          <>
            <div style={{ width: '0.8rem' }} />
            <OutlinedButton onClick={resetTweetCount}>Show Less</OutlinedButton>
          </>
        ) : null}
      </div>
    </>
  );
};
export default TweetGrid;

interface TweetProps {
  tweet: Tweet | undefined;
  delayIdx: number;
  idx: number;
}

const TweetTile: React.FC<TweetProps> = ({ tweet, delayIdx, idx }) => {
  let text;
  let entities;
  let createdAtDate;
  let timeAgoHours;
  let timeAgoString;

  if (tweet !== undefined) {
    text = tweet.full_text;
    entities = tweet.entities;
    createdAtDate = new Date(tweet.created_at);
    timeAgoHours = differenceInHours(new Date(), createdAtDate);
    timeAgoString = convertHoursToString(timeAgoHours);
  }

  return (
    <div
      className={`relative overflow-hidden border-kelp-border border shadow-tight rounded-4 bg-white block px-8 py-6 flex-1 ${
        idx >= 4 ? `tweet` : ``
      }`}
      style={
        {
          flexBasis: 400,
          '--delay': `${delayIdx * 0.1}s`,
        } as CSSProperties
      }
    >
      <div className="flex justify-between mb-3 items-start">
        <div className="flex items-center">
          {tweet !== undefined ? (
            <TweetUserIcon url={tweet.user.profile_image_url} />
          ) : (
            <Loading
              borderRadius="99px"
              width="35px"
              height="35px"
              className="mr-4"
            />
          )}
          <div>
            {tweet !== undefined ? (
              <a
                className="font-bold text-grey-700 block"
                href={`https://twitter.com/${tweet.user.screen_name}`}
                target="_blank"
              >
                {tweet.user.name}
              </a>
            ) : (
              <Loading
                styles={{ marginBottom: 3 }}
                width="125px"
                height="17px"
              />
            )}

            {tweet !== undefined ? (
              <a
                className="text-grey-500 text-sm hover:underline block"
                href={`https://twitter.com/${tweet.user.screen_name}`}
                target="_blank"
                style={{ lineHeight: 1.1 }}
              >
                @{tweet.user.screen_name}
              </a>
            ) : (
              <Loading width="125px" height="17px" />
            )}
          </div>
        </div>
        {tweet !== undefined && createdAtDate !== undefined ? (
          <Tooltip
            content={`${('0' + createdAtDate.getHours()).slice(-2)}:${(
              '0' + createdAtDate.getMinutes()
            ).slice(-2)}, ${createdAtDate.toDateString()}`}
            direction="left"
          >
            <div className="text-grey-500 underline time-ago">
              {timeAgoString}
            </div>
          </Tooltip>
        ) : (
          <Loading width="25px" height="18px" />
        )}
      </div>
      <div className="flex">
        {tweet !== undefined ? (
          <p
            style={{ whiteSpace: 'pre-line' }}
            className="text-grey-600 tweet-body"
          >
            {tweet !== undefined
              ? createTweetComponent({
                  text: text,
                  entities: entities as Entities,
                })
              : null}
          </p>
        ) : (
          <Loading width="100%" height="65px" />
        )}
      </div>
    </div>
  );
};

function convertHoursToString(hours: number) {
  const days = Math.floor(hours / 24);

  if (hours < 1) {
    return `${hours * 60}m`;
  } else if (days > 2) {
    return `${days}d`;
  } else {
    return `${hours}h`;
  }
}

interface TweetUserIconProps {
  url: string | undefined;
}

const TweetUserIcon: React.FC<TweetUserIconProps> = ({ url }) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <div className="relative mr-4">
      <img
        onLoad={() => setLoaded(true)}
        src={`${url}`}
        className="rounded-full"
        style={{ width: 35, height: 35 }}
      />
      {!loaded ? (
        <Loading
          borderRadius="99px"
          width="35px"
          height="35px"
          className="absolute l-0 t-0"
        />
      ) : null}
    </div>
  );
};
