import React, { ReactElement, SVGProps } from 'react';
import FutureUpdates from '../components/Home/FutureUpdates';
import NavBar from '../components/Home/NavBar';
import NavTile from '../components/Home/NavTile';
import TweetGrid from '../components/Home/TweetGrid';
import Footer from '../components/QuickSearch/Footer';
import SEO from '../components/seo';
import AboutModal from '../components/shared/AboutModal';
import '../css/index.css';
import Beet from '../images/svgs/beet.svg';
import Broccoli from '../images/svgs/broccoli.svg';
import Carrots from '../images/svgs/carrots.svg';
import HorizonIcon from '../images/svgs/icons/horizon.svg';
import LinkSvg from '../images/svgs/icons/link.svg';
import ResourcesIcon from '../images/svgs/icons/resources.svg';
import TwitterIcon from '../images/svgs/icons/twitter.svg';
import LogoOutlined from '../images/svgs/logoOutlined.svg';
import Peppers from '../images/svgs/peppers.svg';
import StrokeDivider from '../images/svgs/strokeDivider.svg';
import Tomato from '../images/svgs/tomato.svg';
import { Media } from '../media';

const IndexPage = () => {
  return (
    <div
      className="flex flex-col height-screen max-h-screen"
      style={{ backgroundColor: '#F4FAE8' }}
    >
      <SEO
        title="Let's learn nutrition"
        description="We create resources with the aim of sharing insights into the diverse world of plant foods."
      />
      <AboutModal />
      <Contents />
    </div>
  );
};

export default IndexPage;

const Contents = () => {
  return (
    <div className="max-w-screen overflow-x-hidden">
      <div className="relative w-full" style={{ backgroundColor: '#FBFFF2' }}>
        <div
          className="absolute"
          style={{
            left: 0,
            overflow: 'hidden',
            width: '100vw',
            height: '250px',
            bottom: '-250px',
          }}
        >
          <div
            className="absolute"
            style={{
              backgroundColor: '#FBFFF2',
              height: '100%',
              width: '2000px',
              borderRadius: '0 0 100% 100%',
              left: '50%',
              transform: `translateY(-125px) translateX(-50%)`,
            }}
          ></div>
        </div>
        <div className="relative mx-auto pt-2 w-full" style={{ maxWidth: 975 }}>
          <NavBar />
          <div
            className="mx-auto pt-12 text-center flex items-center flex-col"
            style={{ maxWidth: 650 }}
          >
            <LogoOutlined />
            <h2 className="text-3xl text-white font-bold text-kelp-header font-yoga px-4">
              Let's Learn Nutrition
            </h2>
            <h3
              className="text-lg text-white text-kelp-text px-4"
              style={{ maxWidth: 500 }}
            >
              We create resources with the aim of sharing insights into the
              diverse world of plant foods.
            </h3>
          </div>
          <StrokeDivider
            className="mx-auto mt-10 absolute"
            style={{ left: '50%', transform: `translateX(-50%)` }}
          />
        </div>
      </div>
      <div
        className="flex relative flex-col items-stretch mx-auto pt-40 md:pt-215 w-full"
        style={{ maxWidth: 1050 }}
      >
        <div className="w-full" />
        <Media greaterThanOrEqual="md">
          <Broccoli
            className="absolute"
            style={{ left: '-8rem', top: '-1rem' }}
          />
        </Media>
        <div className="flex-1 relative w-full md:px-6 px-2">
          <Media greaterThanOrEqual="md">
            <Peppers
              className="absolute"
              style={{ right: '-8rem', top: '-4rem' }}
            />
          </Media>
          <SectionHeader
            title="Resources"
            subtext="Learn more about whole foods through our apps and articles"
            Icon={ResourcesIcon}
          />
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
            <NavTile
              linkColor="purple"
              linkText="Find key Nutrients"
              to="quick-search"
              title="Quick Search"
              body="Filter foods by their nutrients. Need to find out where you can get more  calcium, zinc or iron? Search no more."
            />
            <NavTile
              linkText="Browse Foods"
              linkColor="pink"
              to="encyclopedia"
              title="Food Encyclopedia"
              body="How’re avocados good for my health? Where are my breakfast’s walnuts produced? Find out key facts from our 113+ food entries."
            />
          </div>
          <div style={{ height: '3rem' }} />
          <div className="relative">
            <SectionHeader
              title="The Latest"
              subtext="Tweets from doctors and experts focused on plant-based nutrition."
              Icon={TwitterIcon}
              link={{
                url: 'https://twitter.com/i/lists/1335944583524601856',
                text: 'Twitter list',
              }}
            />
            <Media greaterThanOrEqual="md">
              <Carrots
                className="absolute"
                style={{ left: '-13rem', top: '2rem' }}
              />
            </Media>
            <TweetGrid />
          </div>
          <div style={{ height: '3rem' }} />
          <div className="relative">
            <Media greaterThanOrEqual="md">
              <Beet
                className="absolute"
                style={{ right: '-11rem', top: '-9rem' }}
              />
            </Media>
            <SectionHeader
              title="On The Horizon"
              subtext="Planned updates and features"
              Icon={HorizonIcon}
            />
            <Tomato
              className="absolute"
              style={{ left: '-11rem', bottom: '-6rem' }}
            />
            <FutureUpdates />
          </div>
          <div style={{ height: '5rem' }} />
        </div>
      </div>
      <Footer maxWidth={1200} />
    </div>
  );
};

interface SectionHeader {
  title: string;
  subtext: string;
  Icon: (props: SVGProps<SVGElement>) => ReactElement;
  link?: null | {
    url: string;
    text: string;
  };
}

const SectionHeader: React.FC<SectionHeader> = ({
  title,
  subtext,
  Icon,
  link,
}) => {
  return (
    <div className="md:px-8 px-4 mb-6">
      <div className="flex items-center flex-col md:flex-row">
        <Icon className="md:mr-4 flex-shrink-0 mb-2 md:mb-0" />
        <div>
          <h2 className="font-bold text-2xl text-kelp-header font-yoga leading-7 md:text-left text-center">
            {title}
          </h2>
          <h3 className="text-base text-kelp-text font-normal md:text-left text-center">
            {subtext}{' '}
            {link ? (
              <>
                <a
                  href={link.url}
                  target="_blank"
                  className="text-curiousblue inline-flex items-center hover:underline"
                >
                  {link.text}
                  <LinkSvg
                    className="inline ml-1"
                    style={{ height: 16, width: 16 }}
                  />
                </a>
              </>
            ) : null}
          </h3>
        </div>
      </div>
    </div>
  );
};
