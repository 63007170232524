import React from 'react';
import { FutureUpdate } from './FutureUpdates';
import './futureUpdates.css';

const typeStyles = {
  feature: '#9AC755',
  addition: '#DE944D',
  expansion: '#49A1DB',
};

interface FutureUpdateProps {
  update: FutureUpdate;
}

const FutureUpdateTile: React.FC<FutureUpdateProps> = ({ update }) => {
  const { type, title, body } = update;

  const typeColor = typeStyles[type];

  return (
    <div
      className="relative border-kelp-border border shadow-tight rounded-4 bg-white block px-4 py-6 flex-1 flex"
      style={{ flexBasis: 400 }}
    >
      <div className="pl-4 pr-4 flex-1">
        <div className="flex sm:items-center sm:justify-between items-start mb-2 flex-col sm:flex-row">
          <h3 className="text-grey-700 text-lg font-bold mr-2">{title}</h3>
          <div
            className="font-bold text-smm px-4 rounded-4 flex items-center justify-center text-center mt-1 sm:mt-0"
            style={{
              color: typeColor,
              border: `1px solid ${typeColor}`,
              padding: '0.2rem 1rem',
            }}
          >
            {type.toUpperCase()}
          </div>
        </div>
        <p className="text-grey-600 text-base">{body}</p>
      </div>
    </div>
  );
};
export default FutureUpdateTile;
