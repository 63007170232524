import React from 'react';
import FutureUpdateTile from './FutureUpdateTile';

export interface FutureUpdate {
  id: string;
  type: 'expansion' | 'addition' | 'feature' | 'fix';
  title: string;
  body: string;
  upvotes?: number;
  upvotesByUser?: number;
}

const futureUpdates: Array<FutureUpdate> = [
  {
    id: '6046678d4111cb97ed9e6bc8',
    title: 'What am I missing?',
    body: 'Determine what essential nutrients you may be missing from your diet through an easy to use interface',
    type: 'feature',
  },
  {
    id: '6046678d4111cb97ed9e6bc9',
    title: 'Macronutrient Quotas',
    body: 'Addition of macronutrient recommendations and percentages to WFN apps and articles.',
    type: 'addition',
  },
  {
    id: '6046678d4111cb97ed9e6bca',
    title: 'Nutrients Encyclopedia',
    body: 'Currently only foods have been documented in our encyclopedia, we’re looking to add digestible entries for nutrients too.',
    type: 'expansion',
  },
  {
    id: '6046678d4111cb97ed9e6bcb',
    title: 'USDA Food Viewer',
    body: 'View a food from the USDA’s database with all relevant quotas using the WFN interface.',
    type: 'feature',
  },
];

interface FutureUpdatesProps {}

const FutureUpdates: React.FC<FutureUpdatesProps> = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
      {futureUpdates.map((update, idx) => (
        <FutureUpdateTile key={idx} update={update} />
      ))}
    </div>
  );
};
export default FutureUpdates;
