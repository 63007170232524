import React, { CSSProperties } from "react"
import "./loading.css"

interface LoadingProps {
  width?: string
  height?: string
  borderRadius?: string
  styles?: CSSProperties
  className?: string
}

const Loading: React.FC<LoadingProps> = ({
  width = "100%",
  height = "30px",
  borderRadius = "4px",
  styles = {},
  className = "",
}) => {
  return (
    <div
      style={{ ...styles, width, height, borderRadius }}
      className={`${className} loading-box`}
    />
  )
}
export default Loading
