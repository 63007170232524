import React from "react"

interface OutlinedButtonProps {
  style?: "default"
  onClick: () => void
}

const OutlinedButton: React.FC<OutlinedButtonProps> = ({
  style,
  children,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      className="font-bold rounded-4 px-8 py-2 text-base border-box focus-visible"
      style={{
        border: `1px solid #BBCB9C`,
        color: "#828E6C",
      }}
    >
      {children}
    </button>
  )
}
export default OutlinedButton
